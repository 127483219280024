@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,600;1,700&display=swap');

html * {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, 'Poppins', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #171717;
  /* font-size: 16px;
  font-style: normal;
  font-weight: 300; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.os-heavy-slanted {
  font-family: 'Poppins';
  font-weight: bold;
  font-style: italic;
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
  font-size: 16px;
}

/*
 * Main Fonts
 */
.large-header {
  font-family: 'Poppins';
  @apply text-4xl font-bold text-neutral-900 italic;
}
.small-header {
  font-family: 'Poppins';
  @apply text-2xl font-bold text-neutral-900;
}
.body-header {
  font-family: 'Poppins';
  @apply text-lg font-bold text-neutral-900;
}
.body-text-light {
  @apply text-neutral-500;
}
.party-font {
  font-family: 'Poppins';
  @apply text-2xl font-bold text-cyan-500 italic border-danger-300 border;
}
.caption-text {
  @apply text-sm text-neutral-500;
}
.tiny-caption-text {
  @apply text-xs text-neutral-500;
}
.caption-link {
  @apply text-sm text-royal-blue-500 visited:text-royal-blue-500 hover:text-royal-blue-600 hover:underline;
}

/*
 * Component-specific Fonts
 */
.label {
  @apply text-xs font-bold text-neutral-500 uppercase;
}
.label .label-flair {
  /* color: #75ea02 */
  @apply text-purple-500;
}
.input-error-message {
  @apply text-xs text-danger-500;
}
